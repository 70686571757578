@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
    font-family: "Poppins", sans-serif;

    /* Neutral Palette */
    --neutral000: #FCFDFF;
    --neutral100: #E7EDFF;
    --neutral200: #BCC1D1;
    --neutral300: #9599A6;
    --neutral400: #6C6F78;
    --neutral500: #484A50;
    --neutral600: #37393D;
    --neutral700: #252629;
    --neutral800: #151517;
    --neutral900: #050505;

    /* Green Palette */
    --green100: #DDFAE9;
    --green200: #C5F9DA;
    --green300: #92F9BB;
    --green400: #56E38F;
    --green500: #38C771;
    --green600: #1EB25A;
    --green700: #10853F;
    --green800: #065425;
    --green900: #01230F;

    /* Blue Palette */
    --blue100: #D9EAFD;
    --blue200: #C0DCFA;
    --blue300: #A8CDF7;
    --blue400: #8FBDF2;
    --blue500: #79B0EF;
    --blue600: #4C83C2;
    --blue700: #285A94;
    --blue800: #0F3561;
    --blue900: #021B37;

    /* Brown Palette */
    --brown100: #EEE3D7;
    --brown200: #EDD3B7;
    --brown300: #E0B88D;
    --brown400: #C7935C;
    --brown500: #B98044;
    --brown600: #A66C2F;
    --brown700: #7A4915;
    --brown800: #472807;
    --brown900: #231201;
}

body {
    margin: 0;
    font-family: "Poppins", sans-serif;

    -webkit-user-select: none; 
    -moz-user-select: none;    
    -ms-user-select: none;     
    user-select: none;

    transition: background-color 0.3s ease-in-out;
}
.screen {
    background-color: var(--neutral000);
    height: 100svh;
    width: 100svw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}