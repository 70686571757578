

.sudokuGrid {
  display: grid;
  grid-template-columns: repeat(9, 40px); /* 9 columns of 40px each */
  border: 2px solid var(--neutral800);
  width: fit-content;
  height: fit-content;
}

.sudokuCellWrapper {
  -webkit-user-select: none; 
  -moz-user-select: none;    
  -ms-user-select: none;     
  user-select: none;
}

.sudokuCell {
  width: 40px;
  height: 40px;
  color: var(--blue700);
  background-color: var(--neutral000);
  text-align: center;
  font-size: 1.2rem;
  border: 0.1px solid var(--neutral800); /* Add a border for clarity */
  box-sizing: border-box; /* Ensure padding/border doesn't mess up size */
  outline: none; /* Remove default focus outline */
  caret-color: transparent;
  cursor: pointer;
  -webkit-user-select: none; 
  -moz-user-select: none;    
  -ms-user-select: none;     
  user-select: none;
}

.sudokuCell[data-initial="true"] {
  color: black;
}

.sudokuCell[data-y="2"], .sudokuCell[data-y="5"] { 
  border-bottom: 2px solid var(--neutral800); 
} 

.sudokuCell[data-x="2"], .sudokuCell[data-x="5"] {
  border-right: 2px solid var(--neutral800);
}

.sudokuCell.highlight-row {
  background-color: var(--blue100);
}

.sudokuCell.highlight-col {
  background-color: var(--blue100);
}

.sudokuCell.highlight-subgrid {
  background-color: var(--blue100);
}

.sudokuCell.highlight-same-digit {
  background-color: var(--blue300);
}

.sudokuCell.highlighted {
  background-color: var(--blue300);
}

.sudokuGrid:hover, .sudokuCell:hover {
  cursor: pointer;
}

.sudokuCellWrapper {
  position: relative;
}

.notes {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  font-size: 0.7rem; /* Smaller font for notes */
  pointer-events: none; /* Prevent notes from interfering with input */
}

.note {
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
}

.note.active {
  font-weight: bold;
}

.buttons {
  display: flex;
  margin: 10px 20px;
}

.functionButton {
  width: fit-content;
  height: fit-content;
  border: 2px solid var(--neutral800);
  border-radius: 5px;
  padding: 5px 10px;
  margin: 0px 5px;
  /* background-color: #2ee8a7; */
  background-color: var(--blue200);
}

.functionButton:hover {
  cursor: pointer;
}

.headers {
  font-weight: 700;
  font-size: 1.4rem;
  color: var(--neutral900)
}