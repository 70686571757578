.grid {
  width: 500px; /* Width of the drawing area */
  height: 500px; /* Height of the drawing area */
  display: grid;
  grid-template-rows: repeat(10, 50px); /* 10 equal rows */
  grid-template-columns: repeat(10, 50px); /* 10 equal columns */
  position: relative;
  border: 2px solid black;
  cursor: pointer;
}

.wsTitle {
  font-size: 1.8rem;
  margin-bottom: 20px;
}

.wsCell {
  display: flex; /* Enable flexbox */
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
  font-size: 20px;
}

.wsGrid {
  display: grid;
  grid-template-rows: repeat(10, 50px);
  grid-template-columns: repeat(10, 50px);
}

.highlight {
  position: absolute;
  background-color: var(--green500);
  transform-origin: 0 50%;
  opacity: 0.5;
  transition: opacity 0.5s ease;
}

.remainingWords {
  border: 2px solid black;
  width: 600px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 20px 0px;
  padding: 20px;
  box-sizing: border-box;
}

.wordToFind {
  margin: 5px 20px;
}

.foundWord {
  text-decoration: line-through;
  opacity: 0.3;
}
