.logo {
    font-size: 2.8rem;
    font-family: "Poppins", sans-serif;
    font-weight: 800;
}

.puzzle {
    color: var(--blue500);
    /* color: #74b9ff; */
}

.haven {
    color: var(--green400);
    /* color: #55efc4; */
}